/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Rails from "@rails/ujs";
Rails.start();

const images = require.context("../images/", true);

import "jquery";
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "holderjs";
import "heightline";
import "jquery-datetimepicker";

import "../javascripts/application.js";
import "../javascripts/case_partient.js";
import "../javascripts/medical_care_histories.js";
import "../javascripts/specimens.js";
import "../javascripts/medical_status.js";
import "../javascripts/calendar_navigation.js";
import "../javascripts/components.js";
import "../stylesheets/application.scss";

window.$ = $;
window.bootstrap = require("bootstrap");
