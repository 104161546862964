// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/sstephenson/sprockets#sprockets-directives) for details
// about supported directives.
import $ from "jquery";
$.datetimepicker.setLocale("ja");

function set_flash_timer(after_fadeout = function() {}) {
  if ($(".alert.alert-success").length > 0) {
    setTimeout(function() {
      $(".alert.alert-success").fadeOut(1500);
    }, 1000 );
    setTimeout(function() {
      after_fadeout();
    }, 2500 );
  }
}

$(function() {
  set_flash_timer();
  // welcome/index
  $(".block").heightLine();

  // popover
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, { trigger: "hover", html: true });
  });
});
