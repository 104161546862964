import $ from "jquery";

import { baseUrl } from "./common.js";

$(function () {
  function searchAddress(params, onSuccess) {
    $.ajax({
      url: baseUrl() + "/cases/search_address",
      type: "GET",
      data: params,
      success: onSuccess,
    });
  }
  // 郵便番号検索
  $("#case_patient_postal_code").on("change", function () {
    let inputCode = $("#case_patient_postal_code").val();
    searchAddress(
      { mode: "postal_code", postal_code: inputCode },
      function (data) {
        if (data.address) setAddress(data.address);
        // 選択肢の絞り込み
        setOptions(data);
      }
    );
  });
  // 所在地郵便番号検索
  $("#case_patient_stay_postal_code").on("change", function () {
    let inputCode = $("#case_patient_stay_postal_code").val();
    searchAddress(
      { mode: "postal_code", postal_code: inputCode },
      function (data) {
        if (data.address) setStayAddress(data.address);
        setStayMunicipalityOptions(data.municipalities);
      }
    );
  });
  // 市町村名の変更
  $("#case_patient_municipality_id").on("change", function () {
    let selectedId = $("#case_patient_municipality_id").val();
    searchAddress(
      { mode: "municipality", municipality_id: selectedId },
      function (data) {
        // 郵便番号とアドレスはクリアされてよい
        clearPostalCode();
        setAddress(data.address);
        // 選択肢の絞り込み
        setOptions(data);
        if (selectedId == "") clearSelectedOptions();
      }
    );
  });
  // 所在市町村名の変更
  $("#case_patient_stay_municipality_id").on("change", function () {
    let selectedId = $("#case_patient_stay_municipality_id").val();
    searchAddress(
      { mode: "municipality", municipality_id: selectedId },
      function (data) {
        // 郵便番号とアドレスはクリアされてよい
        clearStayPostalCode();
        setStayAddress(data.address);
        // 選択肢の絞り込み
        setStayMunicipalityOptions(data.municipalities);
        if (selectedId == "") clearSelectedStayOptions();
      }
    );
  });
  // 振興局の変更
  $("#case_patient_branch_government_office_id").on("change", function () {
    let selectedId = $("#case_patient_branch_government_office_id").val();
    searchAddress(
      {
        mode: "branch_government_office",
        branch_government_office_id: selectedId,
      },
      function (data) {
        // 郵便番号とアドレスはクリアされてよい
        clearPostalCode();
        setAddress("", true);
        // 選択肢の絞り込み
        setOptions(data);
        if (selectedId == "") clearSelectedOptions();
      }
    );
  });
  // 居住地保健所の変更
  $("#case_patient_residency_health_center_id").on("change", function () {
    let selectedId = $("#case_patient_residency_health_center_id").val();
    searchAddress(
      {
        mode: "residency_health_center",
        residency_health_center_id: selectedId,
      },
    );
  });
  // 所在地入力欄の show/hide。bootstrapのcollapseを使うとスクロールの挙動が変(showで下に伸びて欲しいのに上に伸びたり)で気持ち悪いので、visibility で制御する。
  $('#case_patient_has_stay_address').on('change', function (ev) {
    if (ev.target.checked) {
      $('#stay-address-info').css({opacity: 0.0, visibility: 'visible'}).animate({opacity: 1.0}, 200);
    } else {
      $('#stay-address-info').css({opaticy: 1.0}).animate({opacity: 0.0, visibility: 'hidden'}, 200);
    }
  });
  function syncModelWithView() {
    var hasStayAddress = $('#case_patient_has_stay_address').prop('checked');
    if (hasStayAddress) {
      $('#stay-address-info').css({visibility: 'visible'});
    } else {
      $('#stay-address-info').css({visibility: 'hidden'});
    }
  }
  syncModelWithView();

  // 絞り込みのクリア
  $("#address-clear").on("click", function () {
    // 郵便番号未入力で検索する
    searchAddress({ mode: "postal_code", postal_code: "" }, function (data) {
      // 選択肢の絞り込み
      setOptions(data);
      // 選択値のクリア
      clearPostalCode();
      setAddress("", true);
      clearSelectedOptions();
    });
  });

  function clearPostalCode() {
    $("#case_patient_postal_code").val("");
  }

  function clearStayPostalCode() {
    $("#case_patient_stay_postal_code").val("");
  }

  function setAddress(address) {
    $("#case_patient_address").val(address);
  }

  function setStayAddress(address) {
    $("#case_patient_stay_address").val(address);
  }

  function setOptions(data) {
    setMunicipalityOptions(data.municipalities);
    setBranchGovernmentOfficeOptions(data.branch_government_offices);
    setResidencyHealthCenterOptions(data.residency_health_centers);
  }

  function clearSelectedOptions() {
    // 選択値のクリア
    clearMunicipalityId();
    clearBranchGovernmentOfficeId();
    clearResidencyHealthCenterId();
  }

  function clearSelectedStayOptions() {
    clearStayMunicipalityId();
  }


  function setMunicipalityOptions(municipalities) {
    if (municipalities && municipalities.length > 0) {
      let selectedVal = $("#case_patient_municipality_id").val();
      $("#case_patient_municipality_id > option").remove();
      $("#case_patient_municipality_id").append($("<option>").html("").val(""));
      $.each(municipalities, function (index, municipality) {
        $("#case_patient_municipality_id").append(
          $("<option>").html(municipality.name).val(municipality.id)
        );
        if (municipalities.length == 1) {
          // 選択肢が1つしかない場合は空欄ではなくそちらが選ばれるようにする
          $("#case_patient_municipality_id").val(municipalities[0].id);
        }
        if (municipality.id == selectedVal) {
          // 選択済みのオプションが選択肢にある場合それが選ばれておくようにする
          $("#case_patient_municipality_id").val(selectedVal);
        }
      });
    }
  }

  function setStayMunicipalityOptions(municipalities) {
    if (municipalities && municipalities.length > 0) {
      let selectedVal = $("#case_patient_stay_municipality_id").val();
      $("#case_patient_stay_municipality_id > option").remove();
      $("#case_patient_stay_municipality_id").append($("<option>").html("").val(""));
      $.each(municipalities, function (index, municipality) {
        $("#case_patient_stay_municipality_id").append(
          $("<option>").html(municipality.name).val(municipality.id)
        );
        if (municipalities.length == 1) {
          // 選択肢が1つしかない場合は空欄ではなくそちらが選ばれるようにする
          $("#case_patient_stay_municipality_id").val(municipalities[0].id);
        }
        if (municipality.id == selectedVal) {
          // 選択済みのオプションが選択肢にある場合それが選ばれておくようにする
          $("#case_patient_stay_municipality_id").val(selectedVal);
        }
      });
    }
  }

  function setBranchGovernmentOfficeOptions(branchGovernmentOffices) {
    if (branchGovernmentOffices && branchGovernmentOffices.length > 0) {
      let selectedVal = $("#case_patient_branch_government_office_id").val();
      $("#case_patient_branch_government_office_id > option").remove();
      $("#case_patient_branch_government_office_id").append(
        $("<option>").html("").val("")
      );
      $.each(branchGovernmentOffices, function (index, branchGovernmentOffice) {
        $("#case_patient_branch_government_office_id").append(
          $("<option>")
            .html(branchGovernmentOffice.name)
            .val(branchGovernmentOffice.id)
        );
        if (branchGovernmentOffices.length == 1) {
          // 選択肢が1つしかない場合は空欄ではなくそちらが選ばれるようにする
          $("#case_patient_branch_government_office_id").val(
            branchGovernmentOffices[0].id
          );
        }
        if (branchGovernmentOffice.id == selectedVal) {
          // 選択済みのオプションが選択肢にある場合それが選ばれておくようにする
          $("#case_patient_branch_government_office_id").val(selectedVal);
        }
      });
    }
  }

  function setResidencyHealthCenterOptions(residencyHealthCenters) {
    if (residencyHealthCenters && residencyHealthCenters.length > 0) {
      let selectedVal = $("#case_patient_residency_health_center_id").val();
      $("#case_patient_residency_health_center_id > option").remove();
      $("#case_patient_residency_health_center_id").append(
        $("<option>").html("").val("")
      );
      $.each(residencyHealthCenters, function (index, residencyHealthCenter) {
        $("#case_patient_residency_health_center_id").append(
          $("<option>")
            .html(residencyHealthCenter.name)
            .val(residencyHealthCenter.id)
        );
        if (residencyHealthCenters.length == 1) {
          // 選択肢が1つしかない場合は空欄ではなくそちらが選ばれるようにする
          $("#case_patient_residency_health_center_id").val(
            residencyHealthCenters[0].id
          );
        }
        if (residencyHealthCenter.id == selectedVal) {
          // 選択済みのオプションが選択肢にある場合それが選ばれておくようにする
          $("#case_patient_residency_health_center_id").val(selectedVal);
        }
      });
    }
  }

  function clearMunicipalityId() {
    $("#case_patient_municipality_id").val("");
  }

  function clearStayMunicipalityId() {
    $("#case_patient_stay_municipality_id").val("");
  }

  function clearBranchGovernmentOfficeId() {
    $("#case_patient_branch_government_office_id").val("");
  }

  function clearResidencyHealthCenterId() {
    $("#case_patient_residency_health_center_id").val("");
  }
});
