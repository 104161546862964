import $ from "jquery";

$(function () {
  const baseUrl = $("#date-picker").data("base-url");
  const defaultDate = $("#date-picker").data("date-params");

  $("#date-picker").datetimepicker({
    timepicker: false,
    defaultDate: defaultDate,
    onChangeDateTime: function (dp, $input) {
      const selectDate = new Date($input.val());
      const selectMonth = (selectDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const selectDay = selectDate.getDate().toString().padStart(2, "0");
      document.location.href = baseUrl.replace("%s", `${selectDate.getFullYear()}-${selectMonth}-${selectDay}`);
    },
  });
  $("#calender").on("click", function () {
    $("#date-picker").datetimepicker("show");
  });

  $(".time-picker").datetimepicker({
    datepicker:false, // 時刻のみ表示
    step:10,
    format:'H:i',
  });
});
